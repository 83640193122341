export const YOUTUBE_LINK = "https://youtube.com/@aditdiasporatshopo4543"
export const DIGINCO_WEBSITE_LINK = "https://diginco.com"

export const formContent ={
    processing:"Patientez...",
    send:"Envoyer",
    message:"Votre message"
}


