import React, {FunctionComponent, useState} from "react"
import bannerImg from "../../assets/contact.jpg";
import {Banner} from "../../components/Banner";
import {Section} from "../../components/Section";
import "./contactPageStyle.css"
import {BusinessInfo} from "../../components/BusinessInfo";
import { CiBank } from "react-icons/ci";
import {BsTelephone, BsEnvelope, BsCheck, } from "react-icons/bs";
import {GoLocation} from "react-icons/go";
import {TextInput} from "../../components/textInput/TextInput";
import {TextArea} from "../../components/textInput/TextArea";
import {SelectInput} from "../../components/textInput/SelectInput";
import {BankInfo} from "../../components/bankInfo";
import emailjs, {EmailJSResponseStatus} from "@emailjs/browser"
import {FaRegCheckCircle} from "react-icons/fa";
import {PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID} from "../../constants/env";


export const ContactPage: FunctionComponent = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [contact, setContact] = useState({fullName:"", email:"", phone:"", subject:"", message:""})
    const handleOnChange =(e:any)=> setContact({...contact, [e.target.name]:e.target.value})
    return (
        <div className={"contact"}>
            <Banner banner={bannerImg} pageTitle={"Contact"} />
            <Section>

                <div className={"contact__secOne-container"}>
                    <div className={"contact__title"}>
                        <h2 className={"section__title"}>Laissez nous un message</h2>
                        <p className={"section__paragraph"}>Nous sommes disposés à répondre à toutes vos
                            préoccupations.</p>
                    </div>

                    <div className={"contact__infoCard"}>

                        <BusinessInfo icon={<BsEnvelope className={"statistic__icon"}/>} title={"Email"}
                                      data={"contact@adit-diaspora.com"}/>
                        <BusinessInfo icon={<BsTelephone className={"statistic__icon"}/>} title={"Téléphone"}
                                      data={"+33 6 66 91 46 06"}/>
                        <BusinessInfo icon={<GoLocation className={"statistic__icon"}/>} title={"Adresse"}
                                      data={"37270 Montlouis-sur-Loire / France"}/>

                    </div>

                    <div className={"bank__infoCard"}>


                        <BankInfo icon={<CiBank className={"statistic__icon"}/>} title={"Information Bancaire"}
                                      data1={"RIB: Banque: 10278, Guichet: 06172, N°: 00020322101"}
                                      data2={" IBAN: FR76 1027 8061 7200 0203   2210 163"}
                                      data3={" DOMICILIATION: Cmm Beaumont Sur Oise"}
                        />

                    </div>

                    <div>
                        {
                            emailSent?
                            <div className={"flex-direction email-sent-container"}>
                                <div>
                                    <div className={"flex-direction check-circle"}>
                                        <div className={"flex-direction circle"}>
                                            {/*<FaRegCheckCircle  className={"checkIcon"}/>*/}
                                            <BsCheck className={"checkIcon"}/>
                                        </div>


                                    </div>
                                    <p className={"success-message"}>Felicitations, votre message a été envoyé avec succès!</p>
                                    <div className={"flex-direction"}>

                                        <button className={"btn"} type={"button"}
                                                onClick={(state)=>setEmailSent(!state)}
                                                >Envoyer un autre Message?</button>
                                    </div>
                                </div>


                            </div> :


                                <form className={"form__container"} onSubmit={async (e) => {
                                    e.preventDefault()
                                    console.log("Your request info", contact)

                                    try {
                                        setIsSubmitting(true)
                                const result = await emailjs.send(
                                    SERVICE_ID,
                                    TEMPLATE_ID,
                                    contact,
                                    {
                                        publicKey: PUBLIC_KEY,
                                    },
                                );

                                if(result.status === 200){
                                    setIsSubmitting(false)
                                    setEmailSent(true)
                                }


                            } catch (err) {
                                if (err instanceof EmailJSResponseStatus) {
                                    console.log('EMAIL JS FAILED...', err);
                                    return;
                                }
                                console.log('ERROR', err);
                            }

                        }}>
                            <div className={"form__el-wrapper"}>

                                <TextInput label={"Nom complet"} type={"text"} name={"fullName"}
                                           onChange={handleOnChange} placeholder={"Votre nom"}/>
                                <TextInput label={"Email"} type={"email"} name={"email"} onChange={handleOnChange}
                                           placeholder={"Votre email"}/>

                            </div>
                            <div className={"form__el-wrapper"}>

                                <TextInput type={"tel"} label={"Téléphone"} name={"phone"} onChange={handleOnChange}
                                           placeholder={"Votre numéro"}/>
                                <SelectInput type={"tel"} label={"Objet"} name={"subject"} onChange={handleOnChange}/>

                            </div>
                            <div className={"form__el-wrapper"}>
                                <TextArea label={"Votre message"} name={"message"} onChange={handleOnChange}
                                          placeholder={"Votre message"}/>

                            </div>


                            <div>
                                <div style={{height: '2rem'}}/>
                                <button className={"btn"} type={"submit"} disabled={isSubmitting} >{isSubmitting? "Patientez..." : "Envoyer"}</button>
                            </div>
                        </form>
                        }
                    </div>


                </div>

            </Section>

        </div>
    )
}
