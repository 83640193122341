import React, {FunctionComponent} from "react"
import "./textinput.css"

type props={
    onChange:any
    type:string
    name:string
    placeholder:string
    label:string
}



export const TextInput : FunctionComponent<props> =({onChange, type, name, placeholder, label})=>{
    return(
        <div className={"input__container"}>
            <p style={{color:"#283847"}}>{label}</p>
            <input required name={name} type={type} onChange={onChange} placeholder={placeholder} className={"text__input"} />
        </div>


    )
}
