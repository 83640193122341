import React, {FunctionComponent, ReactNode} from "react"
import {Header} from "../../components/Header";
import {Footer} from "../../components/Footer";
import {FiYoutube} from "react-icons/fi";
import {FaFacebookF, FaTwitter} from "react-icons/fa";
import {DIGINCO_WEBSITE_LINK, YOUTUBE_LINK} from "../../constants/variables";

type props={
    children:ReactNode
}


export const AppWrapper: FunctionComponent<props> = ({children}) => {
    const currentYear = new Date().getFullYear()

    return (
        <>
            <Header />
                {children}
            <Footer />
            <div className={"footer__social-media"}>
                <div className={"footer__sm"}>

                    <a href={YOUTUBE_LINK} rel="noreferrer"  target={"_blank"} className={"sm__icon-hover"}>
                        <FiYoutube  />
                    </a>
                    <div className={"sm__icon-hover"}>
                        <FaFacebookF  />
                    </div>
                    <div className={"sm__icon-hover"}>
                        <FaTwitter />
                    </div>

                </div>
            </div>
            <div className={"copyRight lg__container-padding"}>
                <small >Powered by <a href={DIGINCO_WEBSITE_LINK} target={"__blank"}>Digital Invest Corporate</a> | All rights reserved - {currentYear}</small>
            </div>


        </>
    )
}
