import React, {FunctionComponent} from "react"
import "./navbarStyle.css"
import { NavLink} from "react-router-dom";
import {FiYoutube} from "react-icons/fi";
import {FaFacebookF, FaTwitter} from "react-icons/fa";
import {YOUTUBE_LINK} from "../../constants/variables";
import {MENU_ITEMS} from "../../constants/menuItems";

type props={
    cln?:string
    onClick?:any
}

export const NavBar: FunctionComponent<props> = ({cln, onClick}) => {
    return (
        <div className={"navBar__container"}>
            <nav className={"navBar"}>
                <ul className={cln}>
                    <li className={"nav-item"}>
                            <NavLink to={"/"} onClick={onClick}  className={({isActive})=>(isActive ? "nav-links active" : "nav-links")}>
                                {MENU_ITEMS.home}
                            </NavLink>
                    </li>

                    <li className={"nav-item"}>
                            <NavLink to={"/about"} onClick={onClick}  className={({isActive})=>(isActive ? "nav-links active" : "nav-links")}>
                                {MENU_ITEMS.about}
                            </NavLink>
                    </li>

                        <li className={"nav-item"}>
                            <NavLink to={"/contact"} onClick={onClick}  className={({isActive})=>(isActive ? "nav-links active" : "nav-links")}>
                                {MENU_ITEMS.contact}
                            </NavLink>
                        </li>

                    <li className={"nav-item socialMedia__mobile-menu"}>
                        <div className={"footer__sm"}>

                            <a href={YOUTUBE_LINK} rel="noreferrer"  target={"_blank"} className={"sm__icon-hover"}>
                                <FiYoutube  />
                            </a>
                            <div className={"sm__icon-hover"}>
                                <FaFacebookF  />
                            </div>
                            <div className={"sm__icon-hover"}>
                                <FaTwitter />
                            </div>

                        </div>
                    </li>

                </ul>




            </nav>





        </div>
    )
}
