// YouTubePlayer.tsx
import React from 'react';
import YouTube from 'react-youtube';

interface YouTubePlayerProps {
    videoId: string;
}


const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ videoId }) => {
    const onReady = (event: any) => {
        // You can call any function here when the player is ready
        console.log('Player is ready');
    };

    const onPlay = (event: any) => {
        console.log('Playing');
    };

    const onPause = (event: any) => {
        console.log('Paused');
    };

    const onEnd = (event: any) => {
        console.log('Ended');
    };

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0, // 0 means the video will not start playing automatically
        },
    };

    return (
        <div className="youtube-player-container">
            <YouTube
                videoId={videoId}
                opts={opts}
                onReady={onReady}
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
            />
        </div>

    );
};

export default YouTubePlayer;
