import React, {FunctionComponent, ReactNode} from "react"
import "./sectionStyle.css"

type props ={
    children:ReactNode
    className?:string
}

export const Section: FunctionComponent<props> = ({children, className}) => {
    return (
        <div className={className ? className : "section"}>
            {children}
        </div>
    )
}
