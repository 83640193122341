import React, {FunctionComponent} from "react"
import {Link} from "react-router-dom";
import "./buttonStyle.css"

const STYLES = ["btn--primary", "btn--outline"]
const SIZES = ["btn--medium", "btn--large"]

type props={
    children?:any
    type?:any
    onClick?:any
    buttonStyle?:any
    buttonSize?:any
}


export const Button: FunctionComponent<props> = ({children, type, buttonSize, buttonStyle, onClick}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    return (
        <Link to={"/contact"} className={"btn--mobile"}>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
                {children}
            </button>
        </Link>
    )
}
