import React, {FunctionComponent} from "react"
import  "./noMatchStyle.css"


export const NoMatchPage: FunctionComponent = () => {
    return (
        <div className={"noMatch__container"}>
            <p>Désolé, la page demandée n'existe pas</p>
        </div>
    )
}