import React from 'react';
import './App.css';
import {Route, Routes } from 'react-router-dom';
import {AppWrapper} from "./containers/AppWrapper";
import {HomePage} from "./containers/HomePage";
import {AboutPage} from "./containers/AboutPage";
import {ContactPage} from "./containers/ContactPage";
import {NoMatchPage} from "./containers/NoMatchPage";



function App() {

  return (
    <div className="App">
        <AppWrapper>
            <Routes>
                <Route  path="" element={<HomePage />} />
                <Route  path="/about" element={<AboutPage />} />
                <Route  path="/contact" element={<ContactPage />} />
                <Route  path="*" element={<NoMatchPage />} />
            </Routes>
        </AppWrapper>
    </div>
  );
}

export default App;
