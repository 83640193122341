import React, {FunctionComponent} from "react"
import "./navFooterStyle.css"
import {Link} from "react-router-dom";
import {MENU_ITEMS} from "../../constants/menuItems";


export const NavFooter: FunctionComponent = () => {
    return (
        <div className={"navFooter"}>
            <nav className={"navFooter-Bar"}>
                <ul className={"navFooter-container"}>
                    <li className={"navFooter-item"}>
                        <Link to={"/"} className={"navFooter-links"}>{MENU_ITEMS.home}</Link>
                    </li>

                    <li className={"navFooter-item"}>
                        <Link to={"/about"} className={"navFooter-links"}>{MENU_ITEMS.about}</Link>
                    </li>

                    <li className={"navFooter-item"}>
                        <Link to={"/contact"} className={"navFooter-links"}>{MENU_ITEMS.contact}</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
