import React, {FunctionComponent} from "react"
import "./statisticStyle.css"

type props ={
    icon?:any
    data:number
    title:string
}

export const Statistic: FunctionComponent<props> = ({icon, data, title}) => {
    return (
        <div className={"statistic"}>
            <div className={"statistic__icon-container"}>
                {icon && icon}
                <div className={"statistic__yellow-bg"} ></div>
            </div>
            <p className={"statistic__data"}>
                +{data} {title}
            </p>



        </div>
    )
}
