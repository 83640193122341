import React, {FunctionComponent} from "react"
import "./memberStyle.css"

type props ={
    member:string
    position:string
    country?:string
    picture?:any
}


export const Member: FunctionComponent<props> = ({member, position, country, picture}) => {
    return (
        <div className={"member"}>
            <div className={"member__img"}>
                {picture && <img src={picture} alt={""} className={"member__picture"}/>}
            </div>
            <div className={"member__info"}>
                <h3>{member}</h3>
                <small>{position}</small>
                <p>
                    <small>{country}</small>
                </p>

            </div>

        </div>
    )
}